<template>
  <div class="row">
    <div class="col-md-8">
      <v-input v-model="data.description" placeHolderText="Beschrijving" :show-errors="showErrors" :disabled="false"/>
    </div>
    <div class="col-md-4">
      <v-input v-model="data.price" placeHolderText="Prijs" :show-errors="showErrors" :disabled="false"/>
    </div>
  </div>
</template>

<script>
import baseForm from "@/components/shared/baseForm";

export default {
  extends: baseForm,
  created() {
    this.setValidator(this.data)
  },
  props: {
    index: {
      required: true
    }
  },
  watch: {
    'form': {
      deep: true,
      handler(newValue, oldValue) {
        this.validate()
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.validate()
        }
      }
    }
  },
  methods: {
    validate() {
      this.$emit('submit', {index: this.index, form: this.data})
    }
    // addOption() {
    //   this.damages.push(
    //       {
    //         form: this.setDefaultValue(Object.assign({}, expectedDamageReportForm)),
    //         disabled: false
    //       }
    //   )
    // },
    // setDefaultValue(v) {
    //   Object.keys(v).forEach(key => {
    //     v[key].$defaultValue = v[key].value
    //   })
    //
    //   return v
    // },
    //
    // resetOption(option) {
    //   Object.keys(option.form).forEach(key => {
    //     option.form[key].value = option.form[key].$defaultValue
    //   })
    //   option.disabled = true
    // },
    // remove(index) {
    //   if (index > -1) {
    //     this.damages.splice(index, 1);
    //   }
    // }
  }
}
</script>